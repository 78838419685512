import React from 'react'
import { connect } from 'react-redux'
import Router from 'next/router'
import * as authActions from '../redux/auth'
import { Frap } from '../components/button'
import { Field } from '../components/forms'
import SBheader from '../components/SBheader'
import SBnewFooter from '../components/SBnewFooter'
import { Icon } from '../components/icon'
import close from '../lib/icons/close'
import check from '../lib/icons/check'
import { isNumericOrEmpty } from '../helpers/utility'
import { getLocale, getChallengeMessage } from "../helpers/stateFunctions"
import { TEXTS } from "../helpers/statics"
//import {datalayer_login} from "../helpers/datalayer"
import { ticimaxSignIn } from "../helpers/rioHelper"
import urlcat from 'urlcat'
import Cookies from "js-cookie"
class Login extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      emailOrPhone: "",
      password: "",
      otp: "",
      keepMeSignIn: false,
      pageState: "signIn",  //signIn // forgot //reset.
      validationCode: "", //forgot validation code sent to mail or phone
      newPassword: "",
      repeatPassword: "",
      phoneNumber: "",
      originalChallengeMessage: "",
      ticimaxParams: {
        ref: "",
        returnUrl: "",
      }
    }
  }

  state = {
    emailOrPhone: "",
    password: "",
    otp: "",
    keepMeSignIn: false,
    loadingSignIn: false,
    loadingResetPassword: false,
    loadingAnswerChallenge: false,
    pageState: "signIn",
    validationCode: "",
    newPassword: "",
    repeatPassword: "",
    phoneNumber: "",
    passwordEverFocused: false,
    validationCharCountNotPassed: true,
    validationAtLeastOneNumberNotPassed: true,
    validationAtLeastOneUpperCaseNotPassed: true,
    validationAtLeastOneLowerCaseNotPassed: true,
    ticimaxParams: {
      ref: "",
      returnUrl: "",
    }
  }

  componentDidMount() {
    Cookies.remove("signup")
    this.props.clearChallange()

    let { ref, returnUrl } = Router.query
    if (ref && returnUrl) {
      this.setState({
        ticimaxParams: {
          ref,
          returnUrl
        }
      })
    } else {
      this.setState({
        ticimaxParams: {
          ref: "",
          returnUrl: ""
        }
      })
    }
    //console.log(ref,returnUrl)
  }

  isOnlyNumber(text) {
    let inputTextArray = [...text]
    let numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]
    let isOnlyNumber = true
    inputTextArray.forEach(c => {
      if (numbers.indexOf(c) < 0) {
        isOnlyNumber = false
      }
    })
    return isOnlyNumber
  }

  isEmailOrPhoneValid() {
    let emailOrPhone = this.state.emailOrPhone
    if (emailOrPhone) {
      if (this.isvalidEmail(emailOrPhone) || (emailOrPhone.length >= 10 && emailOrPhone.length <= 12 && this.isOnlyNumber(emailOrPhone))) {
        return true
      }
    }
    return false
  }

  isvalidEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase())
  }

  //////new password validations/////
  isCharCountValid(inputText) {
    let password = inputText
    if (password.length >= 8 && password.length < 25) {
      this.setState({ validationCharCountNotPassed: false })
    } else {
      this.setState({ validationCharCountNotPassed: true })
    }
  }
  isAtLeastOneNumber(inputText) {
    let numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]
    let containsNumber = false
    numbers.forEach(n => {
      if (inputText.indexOf(n) >= 0) {
        containsNumber = true
      }
    })
    this.setState({ validationAtLeastOneNumberNotPassed: !containsNumber })
    return containsNumber
  }
  isAtLeastOneCapitalLetter(inputText) {
    let containsCapital = false
    let numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]
    let inputTextArray = [...inputText]
    inputTextArray.forEach(c => {
      if (c == c.toUpperCase() && numbers.indexOf(c) < 0) {
        containsCapital = true
      }
    })
    this.setState({ validationAtLeastOneUpperCaseNotPassed: !containsCapital })
  }
  isAtLeastOneLowerLetter(inputText) {
    let containsLower = false
    let numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]
    let inputTextArray = [...inputText]
    inputTextArray.forEach(c => {
      if (c == c.toLowerCase() && numbers.indexOf(c) < 0) {
        containsLower = true
      }
    })
    this.setState({ validationAtLeastOneLowerCaseNotPassed: !containsLower })
  }
  isPasswordValid() {
    if (this.state.newPassword.length >= 1 && !this.state.validationCharCountNotPassed && !this.state.validationAtLeastOneNumberNotPassed && !this.state.validationAtLeastOneUpperCaseNotPassed && !this.state.validationAtLeastOneLowerCaseNotPassed) {
      return true
    }
    return false
  }
  checkPassword(inputText) {
    this.isCharCountValid(inputText)
    this.isAtLeastOneNumber(inputText)
    this.isAtLeastOneCapitalLetter(inputText)
    this.isAtLeastOneLowerLetter(inputText)
  }
  ///////////////////////////////
  isUpdatePasswordValid() {
    if (this.state.validationCode.length >= 1 && this.isPasswordValid() && this.state.newPassword == this.state.repeatPassword) {
      return true
    }
    return false
  }

  async onSignInClicked() {
    this.setState({ loadingSignIn: true })

    let { ref, returnUrl } = this.state.ticimaxParams
    //ticimax login ise
    if (ref && returnUrl && ref == "ticimax") {
      console.log("will sign in with ticimax -> ", ref, returnUrl)
      let isEmail = this.state.emailOrPhone.indexOf("@") >= 0
      let params = {
        email: this.state.emailOrPhone,
        phoneNumber: this.state.emailOrPhone,
        password: this.state.password,
        ref
      }
      if (isEmail) {
        delete params.phoneNumber
      } else {
        delete params.email
      }
      //console.log("params is",params)
      let resp = await ticimaxSignIn(params)
      let customToken = resp?.data?.customToken

      let urlParams = { ...Router.query }
      urlParams.extAuth = customToken
      delete urlParams.returnUrl
      delete urlParams.ref

      let redirectUrl = urlcat(Router.query.returnUrl, urlParams)
      console.log("redirectUrl is", redirectUrl)
      //const url = returnUrl+"?processType=2&extAuth="+customToken
      window.location.href = `${redirectUrl}`
      return
    }
    //normal sbux login ise
    this.props.signIn(
      this.state.emailOrPhone, this.state.password,
      (resp) => {
        this.setState({ loadingSignIn: false })
        if (resp && resp.data && resp.data.code && resp.data.code != 50) {
        }
      })
  }

  onCancelClicked() {
    this.props.clearChallange()
    this.setState({ emailOrPhone: "", password: "", otp: "", loadingSignIn: false, pageState: "signIn" })
  }

  onForgotPasswordClicked() {
    this.setState({ pageState: "forgot", emailOrPhone: "" })
  }

  onResetPasswordClicked() {
    this.setState({ loadingResetPassword: true })
    this.props.resetPassword(
      this.state.emailOrPhone,
      (resp) => {
        this.setState({ loadingResetPassword: false })
        if (resp && resp.data && resp.data.code && resp.data.code == 50) {
          this.setState({ pageState: "reset" })
        }
        if (resp && resp.data && resp.data.code && (resp.data.code == 40 || resp.data.code == 98)) {
        }
      }
    )
  }

  answerChallenge(params) {
    this.setState({ loadingAnswerChallenge: true })
    this.props.answerChallenge(
      params,
      (resp) => {
        if (resp && resp.data && resp.data.code && (resp.data.code == 48 || resp.data.code == 49 || resp.data.code == 40)) {
        }
        this.setState({ loadingAnswerChallenge: false })
      })
  }

  onNavItemClicked(tabName) {
    if (tabName == "login") {
      this.setState({ pageState: "signIn" })
    }
  }

  saveFirstChallangeMessage() {
    let _state = { ...this.props }
    let originalChallengeMessage = getChallengeMessage(_state)
    if (originalChallengeMessage) {
      this.setState({ originalChallengeMessage })
    }
  }

  render() {
    let _state = { ...this.props }
    let pageLeftText = TEXTS.sign_in[getLocale(_state)]
    if (this.state.pageState == "forgot" || this.state.pageState == "reset") {
      pageLeftText = TEXTS.forgotPassword[getLocale(_state)]
    }
    return (
      <div>
        <SBheader onNavItemClicked={(tabName) => { this.onNavItemClicked(tabName) }} />

        <main className="sb-login-main height-100 flex flex-column">

          <div className="sb-headerCrate flex flex-column bg-white  flex-shrink-none">
            <div className="lg-flex flex-column base___46s3P">
              <div className="flex flex-grow flex-column ">
                <div className="container--headerCrate sb-headerCrate--content size12of12 ">
                  <h1 className="sb-heading sb-heading--large mb4" tabIndex={-1}>{pageLeftText}</h1></div>
              </div>
            </div>
          </div>

          <div className="sb-contentCrate flex-grow "><span />
            <div className="sb-login-form relative flex-auto">
              <div className="flex-auto">

                {!_state.auth.challengeId && this.state.pageState != "forgot" && this.state.pageState != "reset" && _state.auth.challengeCode != 49 &&
                  <div className="sb-contentColumn mx-auto sb-contentColumn--narrow">
                    <fieldset className="pb6">
                      <Field disable={this.state.loadingSignIn} value={this.state.emailOrPhone} autocomplete={false} className="mb2"
                        valueChanged={(v) => {
                          this.setState({ "emailOrPhone": v.trim() })
                        }}
                      > {TEXTS.phoneOrEmail[getLocale(_state)]}
                      </Field>
                      <span style={{ marginBottom: "10px", display: "block", color: "#adadad" }}>({TEXTS.pleaseEnterPhoneOrEmail[getLocale(_state)]})</span>

                      <Field onkeydown={(e) => { if (e.keyCode == 13 && this.isEmailOrPhoneValid() && this.state.password.length >= 8) { this.onSignInClicked() } }} disable={this.state.loadingSignIn} type={"password"} value={this.state.password} valueChanged={(v, e) => { this.setState({ "password": v }) }} autocomplete={false} className="mb2">
                        {TEXTS.password[getLocale(_state)]}
                      </Field>

                      <p onClick={() => { this.onForgotPasswordClicked() }} style={{ cursor: "pointer" }} className="mb3">
                        <a className="color-greenStarbucks">
                          {TEXTS.forgotYourPassword[getLocale(_state)]}
                        </a>
                      </p>
                    </fieldset>
                    <div className="flex justify-end">
                      <Frap loading={this.state.loadingSignIn} disabled={!this.isEmailOrPhoneValid() || (this.state.password.length < 8)} onClick={() => { this.onSignInClicked() }} className='mr3 mb4'>{TEXTS.sign_in[getLocale(_state)]}</Frap>
                    </div>
                  </div>}

                {_state.auth.challengeInputType == "phone" && (_state.auth.challengeCode == 48 || _state.auth.challengeCode == 49 || _state.auth.challengeCode == 50) && this.state.pageState != "forgot" && this.state.pageState != "reset" &&
                  <div className="sb-contentColumn mx-auto sb-contentColumn--narrow otp">
                    <fieldset className="pb6">
                      <span>{getChallengeMessage(_state)}</span>
                      <Field disable={this.state.loadingAnswerChallenge} value={this.state.phoneNumber} valueChanged={(v) => { if (isNumericOrEmpty(v) && v.length <= 12) { this.setState({ "phoneNumber": v.trim() }) } }} autocomplete={false} className="mb2"
                        onkeydown={(e) => {
                          if (e.keyCode == 13 && this.state.otp.length >= 4) {
                            this.answerChallenge({ challengeAnswer: this.state.otp })
                          }
                        }}>
                        {TEXTS.phoneNumber[getLocale(_state)]}
                      </Field>
                      <p className="mb3"></p>
                    </fieldset>
                    <div className="flex justify-end">
                      <div className="cancelBtn">
                        <Frap onClick={() => { this.onCancelClicked() }} className='mr3 mb4'>{TEXTS.cancel[getLocale(_state)]}</Frap>
                      </div>
                      <div className="signInBtn">
                        <Frap loading={this.state.loadingAnswerChallenge} disabled={this.state.phoneNumber.length < 10} className='mr3 mb4'
                          onClick={() => {
                            this.answerChallenge({
                              challengeAnswer: this.state.phoneNumber,
                              userId: _state.auth.userId,
                            })
                          }}>{TEXTS.send[getLocale(_state)]}
                        </Frap>
                      </div>
                    </div>
                  </div>}

                {_state.auth.challengeInputType == "otp" && (_state.auth.challengeCode == 48 || _state.auth.challengeCode == 49 || _state.auth.challengeCode == 50) && this.state.pageState != "forgot" && this.state.pageState != "reset" &&
                  <div className="sb-contentColumn mx-auto sb-contentColumn--narrow otp">
                    <fieldset className="pb6">
                      <span>{getChallengeMessage(_state) ? getChallengeMessage(_state) : this.state.originalChallengeMessage}</span>
                      <Field onkeydown={(e) => {
                        if (e.keyCode == 13 && this.state.otp.length >= 4) {
                          this.answerChallenge({ challengeAnswer: this.state.otp })
                        }
                      }} disable={this.state.loadingAnswerChallenge} value={this.state.otp} valueChanged={(v) => this.setState({ "otp": v.trim() })} autocomplete={false} className="mb2">
                        {TEXTS.verification_code[getLocale(_state)]}
                      </Field>

                      <p className="mb3"></p>
                    </fieldset>
                    <div className="flex justify-end">
                      <div className="cancelBtn">
                        <Frap onClick={() => { this.onCancelClicked() }} className='mr3 mb4'>{TEXTS.cancel[getLocale(_state)]}</Frap>
                      </div>
                      <div className="signInBtn">
                        <Frap loading={this.state.loadingAnswerChallenge} disabled={this.state.otp.length < 4} className='mr3 mb4'
                          onClick={() => {
                            this.saveFirstChallangeMessage()
                            this.answerChallenge({ challengeAnswer: this.state.otp })
                          }}>
                          {TEXTS.login[getLocale(_state)]}
                        </Frap>
                      </div>
                    </div>
                  </div>}

                {/* forgot-1 */}
                {this.state.pageState == "forgot" &&
                  <div className="sb-contentColumn mx-auto sb-contentColumn--narrow resetPassword">
                    <fieldset className="pb6">
                      <span>{TEXTS.justNeedToConfirm[getLocale(_state)]}</span>
                      <Field disable={this.state.loadingResetPassword} value={this.state.emailOrPhone} autocomplete={false} className="mb2"
                        valueChanged={(v) => {
                          this.setState({ "emailOrPhone": v.trim() })
                        }}> {TEXTS.phoneOrEmail[getLocale(_state)]}
                      </Field>
                      <span style={{ marginBottom: "10px", display: "block", color: "#adadad" }}>({TEXTS.pleaseEnterPhoneOrEmail[getLocale(_state)]})</span>
                      <p className="mb3"></p>
                    </fieldset>
                    <div className="flex justify-end">
                      <div className="signInBtn">
                        <Frap loading={this.state.loadingResetPassword} disabled={!this.isEmailOrPhoneValid()} onClick={() => { this.onResetPasswordClicked() }} className='mr3 mb4'>{TEXTS.reset_password[getLocale(_state)]}</Frap>
                      </div>
                    </div>
                  </div>}

                {/* forgot-2 */}
                {this.state.pageState == "reset" &&
                  <div className="sb-contentColumn mx-auto sb-contentColumn--narrow newPassword">
                    <fieldset className="pb6">
                      <span>{getChallengeMessage(_state)}</span>

                      <fieldset className="pb3">
                        <Field valueChanged={(v) => { this.setState({ validationCode: v }) }} value={this.state.validationCode} autocomplete={false} className="mb2">{TEXTS.valiationCode[getLocale(_state)]}</Field>
                      </fieldset>

                      <fieldset className="pb3">
                        <Field value={this.state.newPassword} type={"password"} autocomplete={false} className="mb2"
                          valueChanged={(v) => {
                            this.setState({ newPassword: v })
                            this.setState({ passwordEverFocused: true })
                            this.checkPassword(v)
                          }} > {TEXTS.newPassword[getLocale(_state)]}
                        </Field>
                        <div className="password-targets" style={{ display: this.state.passwordEverFocused ? "block" : "none" }}>
                          <span className="target-item">
                            {this.state.validationCharCountNotPassed && <Icon className="valign-middle fieldStatus__icon close" path={close} size='20px' />}
                            {!this.state.validationCharCountNotPassed && <Icon className="valign-middle fieldStatus__icon check" path={check} size='20px' />}
                            <span className="title">{TEXTS.from8to25chars[getLocale(_state)]}</span>
                          </span>
                          <span className="target-item">
                            {this.state.validationAtLeastOneNumberNotPassed && <Icon className="valign-middle fieldStatus__icon close" path={close} size='20px' />}
                            {!this.state.validationAtLeastOneNumberNotPassed && <Icon className="valign-middle fieldStatus__icon check" path={check} size='20px' />}
                            <span className="title">{TEXTS.atLeastOneNumber[getLocale(_state)]}</span>
                          </span>

                          <span className="target-item">
                            {this.state.validationAtLeastOneUpperCaseNotPassed && <Icon className="valign-middle fieldStatus__icon close" path={close} size='20px' />}
                            {!this.state.validationAtLeastOneUpperCaseNotPassed && <Icon className="valign-middle fieldStatus__icon check" path={check} size='20px' />}
                            <span className="title">{TEXTS.atLeastOneCapital[getLocale(_state)]}</span>
                          </span>

                          <span className="target-item">
                            {this.state.validationAtLeastOneLowerCaseNotPassed && <Icon className="valign-middle fieldStatus__icon close" path={close} size='20px' />}
                            {!this.state.validationAtLeastOneLowerCaseNotPassed && <Icon className="valign-middle fieldStatus__icon check" path={check} size='20px' />}
                            <span className="title">{TEXTS.atLeastOneLower[getLocale(_state)]}</span>
                          </span>
                        </div>
                      </fieldset>

                      <fieldset className="pb3">
                        <Field valueChanged={(v) => { this.setState({ repeatPassword: v }) }} value={this.state.repeatPassword} type={"password"} autocomplete={false} className="mb2">{TEXTS.repeatPassword[getLocale(_state)]}</Field>
                      </fieldset>

                    </fieldset>
                    <div className="flex justify-end">
                      <div className="cancelBtn">
                        <Frap onClick={() => { this.onCancelClicked() }} className='mr3 mb4'>{TEXTS.cancel[getLocale(_state)]}</Frap>
                      </div>
                      <div className="signInBtn">
                        <Frap loading={this.state.loadingAnswerChallenge} disabled={!this.isUpdatePasswordValid()} className='mr3 mb4'
                          onClick={() => {
                            this.answerChallenge({
                              challengeAnswer: this.state.validationCode,
                              newPassword: this.state.newPassword
                            })
                          }} >{TEXTS.update[getLocale(_state)]}
                        </Frap>
                      </div>
                    </div>
                  </div>}

              </div>
            </div>

            <hr aria-hidden="true" className="sb-rule sb-rule--thin mb4 lg-mb6" />
            <SBnewFooter />
          </div>

        </main>

      </div>)
  }
}


function mapStateToProps(state) {
  return state
}

function mapDispatchToProps(dispatch) {
  return ({

    //AUTH//
    authValueChanged: (k, v) => { dispatch(authActions.valueChanged(k, v)) },
    clearChallange: () => { dispatch(authActions.clearChallange()) },
    signIn: (emailOrPhone, password, callback) => {
      dispatch(authActions.signIn(emailOrPhone, password)).then(data => {
        if (callback) {
          callback(data)
        }
        if (data && data.data && data.data.token) {
          if (Router.query.action && Router.query.action.toLowerCase() == "mpnochange") {
            Router.push("/account?section=paymentMethods&action=mpnochange", "/account/paymentMethods").then(() => { window.scrollTo(0, 0) })
          }
        }
      })
    },
    resetPassword: (emailOrPhone, callback) => {
      dispatch(authActions.resetPassword(emailOrPhone)).then(data => {
        if (callback) {
          callback(data)
        }
      })
    },
    answerChallenge: (params, callback) => {
      dispatch(authActions.answerChallenge(params)).then(data => {
        if (callback) {
          callback(data)
        }
        // if (data && data.data && data.data.token) {
        //   if (Router.query.action && Router.query.action.toLowerCase() == "mpnochange") {
        //     Router.push("/account?section=paymentMethods&action=mpnochange", "/account/paymentMethods").then(() => { window.scrollTo(0, 0) })
        //   }
        //   else if (Router.query.returnUrl) {
        //     let returnUrl = decodeURIComponent(Router.query.returnUrl)
        //     Router.push(returnUrl).then(() => { window.scrollTo(0, 0) })
        //   }
        //   else {
        //     Router.push("/")
        //   }
        // }
      })
    },
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)